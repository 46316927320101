import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SliceList from '../components/sliceList'

export const query = graphql`
  query pageQuery($uid: String!, $lang: String!) {
    prismic {
      allPages(uid: $uid, lang: $lang) {
        edges {
          node {
            _meta {
              uid
              lang
              tags
            }
            title
            meta_title
            meta_keywords
            meta_description
            body {
              ... on PRISMIC_PageBodyAuction {
                type
              }
              ... on PRISMIC_PageBodyContact_panel {
                type
                primary {
                  chat_image
                  chat_footer_icon
                  chat_footer
                  chat_description
                  chat_title
                  chat_footer_link {
                    _linkType
                    ... on PRISMIC_Blogpost {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyCompany_link {
                type
                label
                fields {
                  link_to_external_companies {
                    ... on PRISMIC_External_companies {
                      name
                      service_or_product
                      map
                      logo
                      location_locality
                      location_country
                      strapline
                      website
                      body {
                        ... on PRISMIC_External_companiesBodyText {
                          type
                          primary {
                            section_title
                            text
                          }
                        }
                        ... on PRISMIC_External_companiesBodyLocations {
                          type
                          label
                          primary {
                            section_title
                            phone
                            email
                            fax
                            contact
                            address
                          }
                        }
                        ... on PRISMIC_External_companiesBodyImage_gallery {
                          type
                          label
                          primary {
                            gallery_name
                          }
                          fields {
                            captions
                            image
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyHistory {
                type
                primary {
                  history_title
                }
                fields {
                  history_description
                  history_year
                }
              }
              ... on PRISMIC_PageBodyBanner_gallery {
                type
                fields {
                  title
                  text
                  image
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Blogpost {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyBanner_locations {
                type
                fields {
                  location_image
                }
                primary {
                  location_footer
                  location_text
                  location_title
                }
              }
              ... on PRISMIC_PageBodyBanner_with_caption {
                type
                primary {
                  image
                  title
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Blogpost {
                      title
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyCard_deck {
                type
                primary {
                  deckTitle
                  deckColor
                }
                fields {
                  image
                  text
                  title
                  button_text
                  button_link {
                    ... on PRISMIC_Blogpost {
                      title
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyContact {
                type
                primary {
                  contact_title
                }
              }
              ... on PRISMIC_PageBodyFaq {
                type
                primary {
                  faq_text
                  faq_title
                }
                fields {
                  answer
                  question
                }
              }
              ... on PRISMIC_PageBodyHeadshots {
                type
                fields {
                  image
                  name
                  role
                }
                primary {
                  headshotTitle
                }
              }
              ... on PRISMIC_PageBodyHero {
                type
                primary {
                  image
                  title
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Blogpost {
                      title
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyHubspot_form {
                type
                primary {
                  hubspot_form_id
                  hubspot_portal_id
                  hubspot_region
                }
              }
              ... on PRISMIC_PageBodyInternal_navigation {
                type
                fields {
                  link_location
                  link_name
                }
              }
              ... on PRISMIC_PageBodyImage {
                type
                primary {
                  image
                }
              }
              ... on PRISMIC_PageBodyImage_gallery {
                type
                label
                primary {
                  gallery_name
                }
                fields {
                  image
                }
              }
              ... on PRISMIC_PageBodyLocations {
                type
                label
                fields {
                  address
                  email
                  fax
                  map
                  phone
                  region
                }
                primary {
                  location_title
                }
              }
              ... on PRISMIC_PageBodyPanel {
                type
                primary {
                  panel_color
                  panel_main_icon
                  panel_title
                  panel_footer
                  panel_footer_icon
                }
                fields {
                  panel_body_title
                  panel_body
                }
              }
              ... on PRISMIC_PageBodyQuote {
                type
                primary {
                  role
                  quote
                  image
                  company
                  author
                }
              }
              ... on PRISMIC_PageBodyTestimonial_gallery {
                type
                primary {
                  quote_title
                }
                fields {
                  role
                  quote
                  company
                  author
                }
              }
              ... on PRISMIC_PageBodyStatistics {
                type
                primary {
                  statistics_title
                }
                fields {
                  statistic
                  statistic_description
                }
              }
              ... on PRISMIC_PageBodyText {
                type
                primary {
                  text
                  two_column
                }
              }
              ... on PRISMIC_PageBodyText_with_button {
                type
                primary {
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Blogpost {
                      title
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyVideo {
                type
                primary {
                  embed_video
                  video_link {
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                  video_poster
                  video_title
                }
              }
            }
          }
        }
      }
    }
  }
`


const RenderBody = ({ navState, page }) => {
  return (
    <div>
      {page.body !== null && page.body.length > 0 && page.body.map((slice, index) => {
        return (
          <div key={`slice-${index}`} id={`component-${index}`}>
            <SliceList slice={slice} navState={navState} />
          </div>
        )
      })}
    </div>
  )
}


class page extends Component {
  constructor() {
    super()
    const loggedIn = typeof window === 'undefined' ? false : (localStorage.getItem('bidJSToken') !== null)
    this.state = {
      loggedIn,
      navState:null
    }
    this.toggleLoggedInState.bind(this)
    this.toggleNavState.bind(this)
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.toggleLoggedInState = this.toggleLoggedInState.bind(this)
      window.toggleNavState = this.toggleNavState.bind(this)
    }
  }

  toggleLoggedInState (isLoggedIn = false) {
    this.setState({
      loggedIn: isLoggedIn
    })
  }

  toggleNavState (path) {
    this.setState({
      navState: path
    })
  }

  render () {
    const doc = this.props.data.prismic.allPages.edges.slice(0,1).pop();
    const loggedIn = typeof window === 'undefined' ? false : (localStorage.getItem('bidJSToken') !== null)
    if(!doc) return null;
    return (
      <Layout loggedIn={loggedIn}>
        <SEO title={doc.node.meta_title ? (doc.node.meta_title):(doc.node.title)} keywords={doc.node.meta_keywords} description={doc.node.meta_description} lang={doc.node._meta.lang} />
        <RenderBody page={doc.node} navState={this.state.navState} />
      </Layout>
    )
  }
}
export default page
