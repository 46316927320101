import React from "react";
import EmblaCarousel from "./embla/emblaCarousel";
import PropTypes from 'prop-types'
import ResponsiveImage from './responsiveImage'

const ImageGallery = ({ autoplay, delayLength, loop, slice }) => {
  return (
    <>
      <EmblaCarousel autoplay={autoplay} loop={loop} delayLength={delayLength} slice={slice}>
        {slice.fields.map((item, index) => {
          return (
            <React.Fragment key={`item-${index}`}>
              {item.image && (
                <ResponsiveImage image={item.image} sizes='(min-width: 1024px) 512w, (min-width: 1280px) 640w, 100vw' />
              )}
            </React.Fragment>
          )
        })}
      </EmblaCarousel>
    </>
  )
}

ImageGallery.defaultProps = {
  autoplay: false,
  delayLength: 4000,
  loop: false,
}

ImageGallery.propTypes = {
  autoplay: PropTypes.bool,
  delayLength: PropTypes.number,
  loop: PropTypes.bool,
  slice: PropTypes.object.isRequired,
}

export default ImageGallery
