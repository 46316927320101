import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import ResponsiveImage from './responsiveImage'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import Img from 'gatsby-image'

const bannerLocationQuery = graphql`
  {
    map: file(relativePath: { eq: "apex-offices-world-map.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
const BannerLocation = props => {
  var slice = props.slice
  return (
    <StaticQuery
      query={`${bannerLocationQuery}`}
      render={withPreview(data => {
        return (
          <div className={`text-white flex flex-col component-${slice.type}`} style={{backgroundColor:'#233647'}}>
            <div className='p-8 xl:px-0 pb-20 flex flex-wrap w-full max-w-6xl mx-auto'>
              <div className='w-full sm:w-1/2'>
                <h2 className='text-5xl font-thin'>{RichText.asText(get(slice.primary, 'location_title'), linkResolver)}</h2>
                <div className='text-lg'>{RichText.render(get(slice.primary, 'location_text'), linkResolver)}</div>
              </div>
              <div className='w-full sm:w-1/2 mt-12 sm:mt-0'>
                <Img fluid={data.map.childImageSharp.fluid} className='w-full' alt='map of apex locations around the world' />
              </div>
            </div>
            <footer className='mt-auto pb-8 pt-20 py-4 text-center bg-gray-700'>
              <div className='flex justify-center -mt-24 lg:-mt-32 relative z-10'>
                {slice.fields.map((location, index) => {
                  return (
                    <div className='w-12 h-12 lg:h-20 lg:w-20 -mt-2 lg:mt-1 mx-1 lg:mx-3' key={`location-${index}`}>
                      <ResponsiveImage image={get(location,'location_image')} sizes='(min-width: 640px) calc(50vw - 2rem), (min-width: 1024px) calc(25vw - 2rem), (min-width: 1280px) 600px, calc(50vw - 1.5rem)' />
                    </div>
                  )
                })}
              </div>
              <div className='pt-6 px-4'>
                {RichText.asText(get(slice.primary, 'location_footer'), linkResolver)}
              </div>
            </footer>
          </div>
        )
      }, bannerLocationQuery)}
    />
  )
}

BannerLocation.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerLocation
