import React from "react";
import EmblaCarousel from "./embla/emblaCarousel";
import PropTypes from 'prop-types'
import BannerWithCaption from './bannerWithCaption'

const BannerGallery = ({ autoplay, delayLength, loop, slice }) => {
  return (
    <div className={`component-${slice.type} relative bg-brand-blue text-white`}>
      <EmblaCarousel autoplay={autoplay} loop={loop} delayLength={delayLength}>
        {slice.fields.map((banner, index) => {
          return (
            <div className='flex h-full' key={`banner-${index}`}>
              <BannerWithCaption slice={banner} />
            </div>
          )
        })}
      </EmblaCarousel>
    </div>
  )
}

BannerGallery.defaultProps = {
  autoplay: true,
  delayLength: 4000,
  loop: false,
}

BannerGallery.propTypes = {
  autoplay: PropTypes.bool,
  delayLength: PropTypes.number,
  loop: PropTypes.bool,
  slice: PropTypes.object.isRequired,
}

export default BannerGallery
