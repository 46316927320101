import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import ResponsiveImage from './responsiveImage'


const Image = ({ slice }) => {
  return (
    <figure>
      {get(slice.primary.image, 'url') && (
        <ResponsiveImage image={get(slice.primary, 'image')} />
      )}
    </figure>
  )
}

Image.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Image
