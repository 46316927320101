import React from 'react'
import PropTypes from 'prop-types'


function scripting(slice) {
  return {__html: `
    <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
    <script>
      hbspt.forms.create({
        region: "` + slice.primary.hubspot_region + `",
        portalId: "` + slice.primary.hubspot_portal_id + `",
        formId: "` + slice.primary.hubspot_form_id + `",
      });
    </script>
  `};
}

const Hubspot = ({ slice }) => {
  return (
    <div className='bg-white md:flex w-full mt-5 p-6 lg:px-10' dangerouslySetInnerHTML={scripting(slice)} />
  )
}

Hubspot.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Hubspot
