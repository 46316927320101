import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import { linkResolver } from '../utils/linkResolver';
import Map from './map'
import { RichText } from 'prismic-reactjs'

const CompanyLinkLocation = ({ latitude, longitude, slice, website }) => {

  return (
    <React.Fragment>
      <div className='md:grid grid-cols-3 gap-6'>
        <div>
          <div className='grid grid-cols-3 gap-3'>
            {get(slice.primary, 'contact') && (
              <>
                <span className='font-semibold'>Kontakt:</span>
                <p className='col-span-2'>{slice.primary.contact}</p>
              </>
            )}
            {get(slice.primary, 'phone') && (
              <>
                <span className='font-semibold'>Tel:</span>
                <p className='col-span-2'>{slice.primary.phone}</p>
              </>
            )}
            {get(slice.primary, 'fax') && (
              <>
                <span className='font-semibold'>Fax:</span>
                <p className='col-span-2'>{slice.primary.fax}</p>
              </>
            )}
            {get(slice.primary, 'email') && (
              <>
                <span className='font-semibold'>Email:</span>
                <a href={`mailto:${slice.primary.email}`} target='_blank' rel='noopener noreferrer' className='font-normal cursor-pointer text-brand-blue-light hover:text-brand-orange transform transition duration-150 ease-in-out col-span-2'>{slice.primary.email}</a>
              </>
            )}
            {website && (
              <>
                <span className='font-semibold'>Webseite:</span>
                <a href={website} target='_blank' rel='noopener noreferrer' className='font-normal cursor-pointer text-brand-blue-light hover:text-brand-orange transform transition duration-150 ease-in-out col-span-2'>{website}</a>
              </>
            )}
            {get(slice.primary, 'address') && (
              <>
                <span className='font-semibold'>Adresse:</span>
                <div className='col-span-2'>{RichText.render(get(slice.primary, 'address'), linkResolver)}</div>
              </>
            )}
          </div>
        </div>
        {(latitude && longitude) && (
          <div className='col-span-2'>
            <Map latitude={latitude} longitude={longitude} />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

CompanyLinkLocation.propTypes = {
  slice: PropTypes.object.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  website: PropTypes.string
}

export default CompanyLinkLocation
