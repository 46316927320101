import React, { useState, useEffect, useCallback } from 'react';
import { useRecursiveTimeout } from './useRecursiveTimeout';
import { useEmblaCarousel } from 'embla-carousel/react';
import { DotButton, PrevButton, NextButton, SliceDotButton } from './emblaCarouselButtons';


const EmblaCarouselComponent = ({ autoplay, children, delayLength, loop, slice }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({ loop: loop });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const auto = useCallback(() => {
    if (!embla) return;
    if (!autoplay) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [autoplay, embla]);

  const { play, stop } = useRecursiveTimeout(auto, delayLength);

  const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla.scrollNext(), [embla]);
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [
    embla
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
  }, [embla, onSelect, setScrollSnaps, stop]);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <div>
      <EmblaCarouselReact className='overflow-hidden w-full'>
        <div className='flex select-none'>
          {children.map((Child, index) => (
            <div className='relative min-w-full' key={index}>
              {Child}
            </div>
          ))}
        </div>
      </EmblaCarouselReact>
      <div className='embla__dots'>
        {slice ? (
          <>
            {slice.fields.map((snap, index) => (
              <SliceDotButton
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
                key={index}
                image={snap.image}
                label={snap.history_year}
              />
            ))}
          </>
        ):(
          <>
            {scrollSnaps.map((snap, index) => (
              <DotButton
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
                key={index}
              />
            ))}
          </>
        )}
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default EmblaCarouselComponent;
