import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import ResponsiveImage from './responsiveImage'


const Headshots = ({ slice }) => {
  return (
    <React.Fragment>
      <div className='text-white px-8 pt-4 pb-16' style={{backgroundImage:'linear-gradient(120deg, #003882 0%, #2BAADF 100%)'}}>
        {get(slice.primary, 'headshotTitle') && (
          <h2 className='deck-title font-medium uppercase mb-8'>
            {RichText.asText(get(slice.primary, 'headshotTitle'), linkResolver)}
          </h2>
        )}
        <ul className={`component-${slice.type} grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-16 lg:px-16`}>
          {slice.fields.map((individual, index) => {
            return (
              <React.Fragment key={`card-${index}`}>
                <div className='text-center w-32 md:w-48 mx-auto'>
                  <div className='rounded-full overflow-hidden border-4 border-brand-orange-light mb-6'>
                    <ResponsiveImage image={get(individual, 'image')} sizes='(min-width: 640px) calc(50vw - 2rem), (min-width: 1024px) calc(25vw - 2rem), (min-width: 1280px) 600px, calc(50vw - 1.5rem)' />
                  </div>
                  <div className='flex flex-col justify-center -mx-8'>
                    <div className='text-xl mt-1 font-medium'>{individual.name}</div>
                    <div className='uppercase text-sm'>{individual.role}</div>
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </React.Fragment>
  )
}

Headshots.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Headshots
