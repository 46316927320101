import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import Text from './text'


const TextWithButton = ({ slice }) => {
	var link = get(slice.primary.button_link, 'url',(linkResolver(get(slice.primary.button_link, '_meta',[]),[])))
  return (
    <React.Fragment>
      <div className={`component-${slice.type}`}>
        <Text slice={slice} />
        {get(slice.primary.button_link, 'url') || get(slice.primary.button_link._meta, 'type') === 'auction' ? (
          <a className='button mt-3' href={link}>{get(slice.primary, 'button_text')}</a>
        ):(
          <Link className='button mt-3' to={link}>{get(slice.primary, 'button_text')}</Link>
        )}
      </div>
    </React.Fragment>
  )
}

TextWithButton.propTypes = {
  slice: PropTypes.object.isRequired
}

export default TextWithButton
