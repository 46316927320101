import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import ResponsiveImage from './responsiveImage'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'

const Quote = ({ slice }) => {

  return (
    <blockquote className={`component-${slice.type} relative md:gap-4 md:grid md:grid-flow-col mx-10`}>
      <svg className='transform -translate-x-8 -translate-y-3 absolute h-8 left-0 text-brand-orange opacity-25 sm:opacity-75 top-0 w-8' fill='currentColor' viewBox='0 0 32 32'>
        <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z'></path>
      </svg>
      <div className='quote mb-8 text-2xl relative leading-tight font-medium md:flex md:items-center md:flex-grow md:text-3xl md:col-span-1 md:mb-0'>
        {RichText.render(get(slice.primary, 'quote'), linkResolver)}
      </div>
      <footer className='mt-3 sm:mt-0 flex items-center sm:block'>
        <span className='bg-white mr-3 border-2 border-brand-blue rounded-full mx-auto h-12 w-12 md:mb-3 md:h-24 md:w-24 overflow-hidden block'>
          {get(slice.primary.image, 'url') && (
            <ResponsiveImage image={get(slice.primary, 'image')} sizes='3rem' />
          )}
        </span>
        <small>
          <div className='block md:text-center text-gray-900 font-semibold leading-none text-sm my-1'>{get(slice.primary, 'author')}</div>
          <div className='block md:text-center text-gray-800 text-xs'>{get(slice.primary, 'role')}{get(slice.primary, 'company') && ( <span>/ {get(slice.primary, 'company')}</span>)}</div>
        </small>
      </footer>
    </blockquote>
  )
}

Quote.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Quote
