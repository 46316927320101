import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import background from '../images/globe.png'

const Panel = ({ slice }) => {
  return (
    <div className={`component-${slice.type} px-8 py-16`} style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
      <div className='max-w-6xl mx-auto sm:text-col-2'>
        <h2 className='font-hairline text-5xl text-brand-blue -mt-1 mb-6 leading-none'>{RichText.asText(get(slice.primary, 'statistics_title'))}</h2>
        <dl>
          {slice.fields.map((item, index) => {
            return (
              <React.Fragment key={`item-${index}`}>
                <dt className='text-2xl font-normal leading-none'>{item.statistic}</dt>
                <dd className='mb-5'>{item.statistic_description}</dd>
              </React.Fragment>
            )
          })}
        </dl>
      </div>
    </div>
  )
}

Panel.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Panel
