import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';
import ResponsiveImage from './responsiveImage'

const Card = ({ fields }) => {
  var link = get(fields.button_link, 'url',(linkResolver(get(fields.button_link, '_meta',[]),[])))

  return (
    <li className='component-card flex flex-col flex-1 relative'>
      {get(fields.image, 'url') && (
        <ResponsiveImage desktopClasses='p-0' containerClasses='relative overflow-hidden w-full' imageClasses='w-auto h-full' image={get(fields, 'image')} sizes='(min-width: 640px) calc(50vw - 2rem), (min-width: 1024px) calc(25vw - 2rem), (min-width: 1280px) 600px, calc(50vw - 1.5rem)' />
      )}
      <div className='flex flex-col flex-grow'>
        <div className='py-4'>
          <h3>{RichText.asText(get(fields, 'title'), linkResolver)}</h3>
          <div>
            {RichText.render(get(fields, 'text'), linkResolver)}
          </div>
        </div>
        {link !== '/undefined' && (
          <div className='mt-auto'>
            {get(fields.button_link, 'url') ? (
              <a className='button' href={link}>{get(fields, 'button_text')}</a>
            ):(
              <Link className='button' to={link}>{get(fields, 'button_text')}</Link>
            )}
          </div>
        )}
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired,
}

export default Card
