import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import ResponsiveImage from './responsiveImage'

const Panel = ({ slice }) => {
  var panelColorClass = slice.type + '-default'
  if (slice.primary.panel_color) {
    panelColorClass = slice.type + '-' + slice.primary.panel_color.toLowerCase()
  }
  return (
    <div className={`component-${slice.type} relative ${panelColorClass}`}>
      {get(slice.primary, 'panel_main_icon') && (
        <div className='absolute top-0 right-0 h-12 w-12 m-2 sm:h-20 sm:w-20 sm:m-6'>
          <ResponsiveImage image={get(slice.primary, 'panel_main_icon')} sizes='(min-width: 640px) calc(50vw - 2rem), (min-width: 1024px) calc(25vw - 2rem), (min-width: 1280px) 600px, calc(50vw - 1.5rem)' />
        </div>
      )}
      {get(slice.primary, 'panel_title') && (
        <h2 className='panel-title mt-0 pl-8 pt-8 pb-0 pr-20'>{RichText.asText(get(slice.primary, 'panel_title'), linkResolver)}</h2>
      )}
      {get(slice, 'fields') && (
        <div className='panel-body p-4 p-8 pt-3 lg:flex flex-row flex-wrap'>
          {slice.fields.map((panelBody, index) => {
            return (
              <div key={`panel_pody-${index}`} className={`px-3 ${panelBody.panel_body[0].type === 'image' ? ('w-full flex-initial panel-body-image'):('panel-body-text')}`}>
                {get(panelBody, 'panel_body_title') && (
                  <h3 classname='inline font-semibold lg:h-10'>{RichText.asText(get(panelBody, 'panel_body_title'), linkResolver)}</h3>
                )}
               {RichText.render(get(panelBody, 'panel_body'), linkResolver)}
              </div>
            )
          })}
        </div>
      )}
      {get(slice.primary, 'panel_footer') && RichText.asText(get(slice.primary, 'panel_footer')) !== '' && (
        <div className='panel-footer inset-x w-full bottom-0 px-8 py-4 flex flex-row content-center font-semibold'>
          {get(slice.primary, 'panel_footer_icon') && (
            <div className='h-16 w-16 mr-5'>
              <ResponsiveImage image={get(slice.primary, 'panel_footer_icon')} sizes='(min-width: 640px) calc(50vw - 2rem), (min-width: 1024px) calc(25vw - 2rem), (min-width: 1280px) 600px, calc(50vw - 1.5rem)' />
            </div>
          )}
          <div className='flex flex-col justify-center'>
            {RichText.render(get(slice.primary, 'panel_footer'), linkResolver)}
          </div>
        </div>
      )}
    </div>
  )
}

Panel.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Panel
