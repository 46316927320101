import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'


const Video = ({ slice }) => {
  var link = get(slice.primary.video_link, 'url',(linkResolver(get(slice.primary.video_link, '_meta',[]),[])))
  if (get(slice.primary, 'embed_video.embed_url')) {    var padding = {
      paddingBottom: 100 / (get(slice.primary, 'embed_video.width') / get(slice.primary, 'embed_video.height')) + '%'
    }
    var embed = get(slice.primary, 'embed_video.embed_url')
    if (embed !== null && embed.includes('watch')) {
      embed = 'https://www.youtube.com/embed/' + embed.split('v=')[1]
      var ampersandPosition = embed.indexOf('&')
      if(ampersandPosition !== -1) {
        embed = embed.substring(0, ampersandPosition)
      }
    }
  }
  return (
    <React.Fragment>
      {get(slice.primary, 'embed_video') ? (
        <div className='relative' style={padding}>
          <iframe className='absolute inset-0' width='100%' height='100%' title={slice.primary.embed_video.title} src={`${embed}?feature=oembed`} allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
        </div>
      ) : (
        <>
          {get(slice.primary, 'video_title' !== '') && (<div>{RichText.render(get(slice.primary, 'video_title'), linkResolver)}</div>)}
          {link !== '' && link !== null && link !== '/undefined'? (
            <video controls poster={get(slice.primary.video_poster, 'url')}>
              <source src={link} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          ):(
            <strong>No video available</strong>
          )}
        </>
      )}
    </React.Fragment>
  )
}

Video.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Video
