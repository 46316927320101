import React from 'react'
import PropTypes from 'prop-types'
import Auction from './auction'
import BannerGallery from './bannerGallery'
import BannerLocation from './bannerLocation'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import ContactPanel from './contactPanel'
import Contact from './contact'
import CompanyLink from './companyLink'
import FAQs from './faq'
import Headshots from './headshots'
import Hero from './hero'
import History from './history'
import Hubspot from './hubspot'
import InternalNavigation from './internalNavigation'
import Image from './image'
import ImageGallery from './imageGallery'
import Locations from './locations'
import Map from './map'
import Panel from './panel'
import Quote from './quote'
import Statistics from './statistics'
import TestimonialGallery from './testimonialGallery'
import Text from './text'
import TextWithButton from './textWithButton'
import Video from './video'

const SliceList = ({ navState, slice }) => {
  return (
    <React.Fragment>
      {slice.type === 'auction' && (
        <Auction />
      )}
      {!navState && (
        <>
          {slice.type === 'banner_gallery' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <BannerGallery autoplay loop delayLength={5000} slice={slice} />
            </div>
          )}
          {slice.type === 'banner_locations' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <BannerLocation slice={slice} />
            </div>
          )}
          {slice.type === 'banner_with_caption' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <BannerWithCaption slice={slice} />
            </div>
          )}
          {slice.type === 'contact_panel' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <ContactPanel slice={slice} />
            </div>
          )}
          {slice.type === 'card_deck' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <CardDeck slice={slice} />
            </div>
          )}
          {slice.type === 'company_link' && (
            <div className='max-w-screen-xl mx-auto md:px-4 py-4'>
              <CompanyLink slice={slice} />
            </div>
          )}
          {slice.type === 'contact' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Contact slice={slice} />
            </div>
          )}
          {slice.type === 'faq' && (
            <div className='max-w-screen-xl mx-auto md:px-4 py-4'>
              <FAQs slice={slice} />
            </div>
          )}
          {slice.type === 'headshots' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Headshots slice={slice} />
            </div>
          )}
          {slice.type === 'hero' && (
            <Hero slice={slice} />
          )}
          {slice.type === 'history' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <History loop delayLength={5000} slice={slice} />
            </div>
          )}
          {slice.type === 'hubspot_form' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Hubspot slice={slice} />
            </div>
          )}
          {slice.type === 'internal_navigation' && (
            <div className='max-w-screen-xl mx-auto px-4 pb-4 -mt-4'>
              <InternalNavigation slice={slice} />
            </div>
          )}
          {slice.type === 'image' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Image slice={slice} />
            </div>
          )}
          {slice.type === 'image_gallery' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <ImageGallery autoplay delayLength={5000} slice={slice} />
            </div>
          )}
          {slice.type === 'locations' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Locations slice={slice} />
            </div>
          )}
          {slice.type === 'map' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Map slice={slice} />
            </div>
          )}
          {slice.type === 'panel' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Panel slice={slice} />
            </div>
          )}
          {slice.type === 'quote' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Quote slice={slice} />
            </div>
          )}
          {slice.type === 'statistics' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Statistics slice={slice} />
            </div>
          )}
          {slice.type === 'testimonial_gallery' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <TestimonialGallery autoplay loop delayLength={5000} slice={slice} />
            </div>
          )}
          {slice.type === 'text' && (
            <div className='max-w-screen-xl mx-auto px-10'>
              <Text slice={slice} />
            </div>
          )}
          {slice.type === 'text_with_button' && (
            <div className='max-w-screen-xl mx-auto px-10'>
              <TextWithButton slice={slice} />
            </div>
          )}
          {slice.type === 'video' && (
            <div className='max-w-screen-xl mx-auto px-4 py-4'>
              <Video slice={slice} />
            </div>
          )}
        </>
      )}
    </React.Fragment>
  )
}

SliceList.propTypes = {
  navState: PropTypes.string,
  slice: PropTypes.object.isRequired,
}

export default SliceList
