import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'

const Contact = ({slice}) => {
  return (
    <div className='bg-white p-6 lg:p-12 pt-6'>
      <h2 className='heading'>{RichText.asText(get(slice.primary, 'contact_title'))}</h2>
      <form
        name='contact'
        method='POST'
        action='/success'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
      >
        <input type='hidden' name='bot-field' />
        <input type='hidden' name='form-name' value='contact' />

        <div className='mx-auto max-w-4xl'>
          <div className='mb-5'>
            <label className='font-normal text-gray-700 block mb-2' htmlFor='name'>Name<sup className='text-brand-orange ml-1'>*</sup></label>
            <input className='h-12 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full' type='text' name='name' id='name' required />
          </div>
          <div className='mb-5'>
            <label className='font-normal text-gray-700 block mb-2' htmlFor='email'>E-Mail-Addresse<sup className='text-brand-orange ml-1'>*</sup></label>
            <input className='h-12 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full' type='email' name='email' id='email' required />
          </div>
          <div className='mb-5'>
            <label className='font-normal text-gray-700 block mb-2' htmlFor='subject'>Betreff<sup className='text-brand-orange ml-1'>*</sup></label>
            <input className='h-12 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full' type='text' name='subject' id='subject' required />
          </div>
          <div className='mb-5'>
            <label className='font-normal text-gray-700 block mb-2' htmlFor='telephone'>Telefonnummer</label>
            <input className='h-12 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full' type='tel' name='telephone' id='telephone' />
          </div>
          <div className='mb-5'>
            <label className='font-normal text-gray-700 block mb-2' htmlFor='company'>Unternehmen</label>
            <input className='h-12 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full' type='text' name='company' id='company' />
          </div>
          <div className='mb-5'>
            <label className='font-normal text-gray-700 block mb-2' htmlFor='message'>Nachricht<sup className='text-brand-orange ml-1'>*</sup></label>
            <textarea className='h-32 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full' name='message' id='message' required></textarea>
          </div>
          <div className='text-right'>
            <button type='submit' className='button'>Abschicken</button>
          </div>
        </div>
      </form>
    </div>
  )
}

Contact.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Contact
