import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import ResponsiveImage from './responsiveImage'

const Footer = ({ slice }) => {
  return (
    <>
      {get(slice.primary, 'chat_footer_icon') && (
        <div className='h-16 w-16'>
          <ResponsiveImage image={get(slice.primary, 'chat_footer_icon')} sizes='(min-width: 640px) calc(100vw - 3rem), (min-width: 1024px) calc(50vw - 2rem), (min-width: 1280px) 608px, calc(100vw - 2em)' imageClasses='absolute h-full w-full top-0 left-0 object-cover' />
        </div>
      )}
      {get(slice.primary, 'chat_footer_link') && (
        <div className='ml-4'>{RichText.render(get(slice.primary, 'chat_footer'), linkResolver)}</div>
      )}
    </>
  )
}

const ContactPanel = ({ slice }) => {
  var link = get(slice.primary.chat_footer_link, 'url',(linkResolver(get(slice.primary.chat_footer_link, '_meta',[]),[])))
  return (
    <div className={`component-${slice.type} grid grid-cols-1 md:grid-cols-2 bg-brand-blue-lighter text-brand-blue`}>
      <div className='relative'>
        <div className='p-5 mb-4'>
          {get(slice.primary, 'chat_title') && (
            <h2 className='m-0 p-0 text-lg'>{RichText.asText(get(slice.primary, 'chat_title'), linkResolver)}</h2>
          )}
          {get(slice.primary, 'chat_description') && (
            <>{RichText.render(get(slice.primary, 'chat_description'), linkResolver)}</>
          )}
        </div>
        {get(slice.primary.chat_footer_link, 'url') ? (
          <a href={link} className='flex flex-row items-center p-5 footer hover:text-brand-orange'>
            <Footer slice={slice} />
          </a>
        ):(
          <Link to={link} className='flex flex-row items-center p-5 footer hover:text-brand-orange'>
            <Footer slice={slice} />
          </Link>
        )}
      </div>
      {get(slice.primary, 'chat_image') && (
        <div className='bg-cover bg-center h-48 md:h-auto' style={{background:`url(${slice.primary.chat_image.url})`}} />
      )}
    </div>
  )
}

ContactPanel.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default ContactPanel
