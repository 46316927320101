import React from "react";
import EmblaCarousel from "./embla/emblaCarousel";
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'

const History = ({ autoplay, delayLength, loop, slice }) => {
  return (
    <div className={`component-${slice.type} bg-brand-blue-lighter px-8 py-10 relative mt-8 text-brand-blue`}>
      <h2 className='m-0 uppercase'>{RichText.asText(get(slice, 'primary.history_title'), linkResolver)}</h2>
      <div className='max-w-screen-md mx-auto my-4 lg:pb-16'>
        <EmblaCarousel autoplay={autoplay} loop={loop} delayLength={delayLength} slice={slice}>
          {slice.fields.map((history, index) => {
            return (
              <div className='flex flex-row flex-wrap md:flex-no-wrap px-8 md:px-16' key={`banner-${index}`}>
                <div className='year'>{get(history, 'history_year')}</div>
                <div className='md:ml-4 mt-2 md:p-4 text-brand-blue'>{RichText.render(get(history, 'history_description'), linkResolver)}</div>
              </div>
            )
          })}
        </EmblaCarousel>
      </div>
    </div>
  )
}

History.defaultProps = {
  autoplay: false,
  delayLength: 4000,
  loop: false,
}

History.propTypes = {
  autoplay: PropTypes.bool,
  delayLength: PropTypes.number,
  loop: PropTypes.bool,
  slice: PropTypes.object.isRequired,
}

export default History
