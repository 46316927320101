import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import Card from './card'


const CardDeck = ({ slice }) => {
  var cardColorClass = slice.type + '-default'
  if (slice.primary.deckColor) {
    cardColorClass = slice.type + '-' + slice.primary.deckColor.toLowerCase()
  }
  return (
    <React.Fragment>
      <div className={`component-${slice.type} px-8 py-5 ${cardColorClass}`}>
        {slice.primary.deckTitle !== null && (
          <h2 className='heading'>
            {RichText.asText(get(slice.primary, 'deckTitle'))}
          </h2>
        )}
        <ul className={`md:p-6 md:grid ${slice.fields.length === 2 ? 'grid-cols-2':'grid-cols-3'} gap-12`}>
          {slice.fields.map((card, index) => {
            return (
              <React.Fragment key={`card-${index}`}>
                <Card fields={card} />
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </React.Fragment>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CardDeck
