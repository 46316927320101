import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CompanyLinkLocation from './companyLinkLocation'
import Flag from './flag'
import ImageGallery from './imageGallery'
import ResponsiveImage from './responsiveImage'
import Text from './text'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'


class CompanyLink extends PureComponent {
  constructor() {
    super()
    this.state = {
      FAQExpanded: false
    }
    this.toggleFAQ = this.toggleFAQ.bind(this);
  }
  toggleFAQ(id) {
    if (id === this.state.accordionId) {
      this.setState({
        accordionId:false
      })
    } else {
      this.setState({
        accordionId:id
      })
    }
  }

  render() {
    var slice = this.props.slice
    if (slice.primary) {
      slice = slice.primary
    }

    return (
      <ul>
        {slice.fields.map((company, index) => {
          company = company.link_to_external_companies
          var companyIndex = index
          return (
            <li className='bg-white mb-5 rounded-sm relative component-company_link' key={`company-${index}`}>
              <div className='p-4 md:p-6 pb-0'>
                <ResponsiveImage image={company.logo} sizes='(min-width: 640px) calc(100vw - 3rem), (min-width: 1024px) calc(50vw - 2rem), (min-width: 1280px) 608px, calc(100vw - 2em)' containerClasses='pull-right w-24 md:w-40' desktopClasses='p-0' imageClasses='right-0' />
                <h2 className='heading'>{RichText.asText(get(company, 'name'), linkResolver)}</h2>
                {get(company, 'service_or_product') && (<h3 className='text-brand-blue font-semibold mt-2'>{RichText.asText(get(company, 'service_or_product'), linkResolver)}</h3>)}
                {(get(company, 'location_country') && get(company, 'location_locality')) && (
                  <h4 className='text-brand-blue font-semibold mt-2'>
                    {get(company, 'location_country') && (<div className='pull-left mr-2 mt-px'><Flag language={get(company, 'location_country')} hideLabel={true} /></div>)}
                    {get(company, 'location_locality') && (<span className='capitalize'>{get(company, 'location_locality')},</span>)}
                    {get(company, 'location_country') && (<span className='capitalize'> {get(company, 'location_country')}</span>)}
                  </h4>
                )}
                <p className='font-semibold mt-2 text-gray-800'>{company.strapline}</p>
              </div>
              <div className='md:p-6'>
                <ul className='mb-6'>
                  {company.body.map((section, index) => {
                    var title = section.primary.section_title || RichText.asText(section.primary.gallery_name)
                    var accordion = title + companyIndex
                    return (
                      <li className='overflow-hidden border-b transform transition-all my-1' key={`section-${index}`}>
                        <button className={`m-0 p-4 text-brand-blue font-semibold text-lg block hover:text-brand-orange w-full text-left outline-none ${this.state.accordionId === accordion ? 'open': 'closed'}`} aria-haspopup='true' aria-expanded={this.state.FAQExpanded} onClick={() => this.toggleFAQ(`${accordion}`)} onKeyDown={() => this.toggleFAQ(`${accordion}`)} >
                          {title}
                        </button>
                        <div className={`text-lg p-4 lg:pl-8 ${this.state.accordionId === accordion ? 'block': 'hidden'}`}>
                          {this.state.accordionId === accordion && (
                            <>
                              {section.type === 'image_gallery' && (
                                <div className='max-w-screen-md mx-auto relative px-4 py-4'>
                                  <ImageGallery autoplay delayLength={5000} slice={section} />
                                </div>
                              )}
                            </>
                          )}
                          {section.type === 'text' && (
                            <div className='max-w-screen-xl mx-auto px-4'>
                              <Text slice={section} />
                            </div>
                          )}
                          {section.type === 'locations' && (
                            <div className='max-w-screen-xl mx-auto px-4'>
                              <CompanyLinkLocation slice={section} latitude={company.map.latitude} longitude={company.map.longitude} website={get(company, 'website')} />
                            </div>
                          )}
                        </div>
                      </li>
                    )
                  })}
                </ul>
                <a href={get(company, 'website')} className='button bg-white border-brand-orange text-brand-orange hover:bg-brand-orange hover:text-white' target='_blank' rel='noopener noreferrer'><i className='fa fa-external-link-square' aria-hidden='true' /> Visit site</a>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }
}

CompanyLink.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CompanyLink
