import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Link } from 'gatsby'
import ResponsiveImage from './responsiveImage'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'


const BannerWithCaption = ({ slice }) => {
  if (slice.primary) {
    slice = slice.primary
  }
  var link = get(slice.button_link, 'url',(linkResolver(get(slice.button_link, '_meta',[]),[])))

  return (
    <div className='component-banner_with_caption grid grid-cols-1 md:grid-cols-2'>
      <div className='caption bg-brand-blue text-white flex flex-col p-10 xl:p-16 justify-center'>
        <h1 className='m-0 text-3xl md:text-4xl xl:text-5xl'>{RichText.asText(get(slice, 'title'), linkResolver)}</h1>
        {get(slice, 'text') && (<div className='mt-3 subtext'>{RichText.render(get(slice, 'text'), linkResolver)}</div>)}
        {link !== '/undefined' && (
          <div className='mt-3 md:mt-auto button-container'>
            {get(slice.button_link, 'url') ? (
              <a className='button font-bold' href={link}>{get(slice, 'button_text')}</a>
            ):(
              <Link className='button font-bold' to={link}>{get(slice, 'button_text')}</Link>
            )}
          </div>
        )}
      </div>
      <ResponsiveImage image={slice.image} sizes='(min-width: 640px) calc(100vw - 3rem), (min-width: 1024px) calc(50vw - 2rem), (min-width: 1280px) 608px, calc(100vw - 2em)' imageClasses='absolute h-full w-full top-0 left-0 object-cover' containerClasses='responsive-image relative overflow-hidden w-full' />
    </div>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
