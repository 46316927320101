import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'


const Locations = ({ slice }) => {

  return (
    <div className='component-location'>
      <h2 className='heading'>{RichText.asText(get(slice.primary, 'location_title'), linkResolver)}</h2>
      <div className='lg:flex flex-row flex-wrap'>
        {slice.fields.map((location, index) => {
          return (
            <div className='location pb-10 px-8' key={`location-${index}`}>
              <h3 className='heading'><i className='w-8 fa fa-map-marker' aria-hidden='true'></i>{location.region}</h3>
              <div className='ml-8 md:grid grid-cols-2 gap-6'>
                {RichText.render(get(location, 'address'), linkResolver)}
                <div>
                  <p className='pb-2'>Tel: <a href={`tel:${location.phone}`}>{location.phone}</a></p>
                  {location.fax && <p className='pb-2'>Fax: {location.fax}</p>}
                  {location.email && <p className='pb-2 font-normal text-brand-blue-light hover:text-brand-orange transform transition duration-150 ease-in-out'><a href={`mailto:${location.email}`}>{location.email}</a></p>}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Locations.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Locations
