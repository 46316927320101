import React from 'react'
import PropTypes from 'prop-types'

const Map = ({ latitude, longitude }) => {

  return (
    <div className='relative' style={{paddingBottom:'56.25%'}}>
      <iframe
        className='absolute inset-0'
        width='100%'
        height='100%'
        title='GoogleMap'
        loading='lazy'
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDNMejtwjaIEGjJhg67_uQF9ZLZpvjQX4o&q=${latitude},${longitude}`} allowFullScreen />
    </div>
  )
}

Map.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired
}

export default Map
