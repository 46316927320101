import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'


const InternalNavigation = ({ slice }) => {
  return (
    <ul className={`component-${slice.type} hidden lg:flex flex-row bg-gray-300 justify-between divide-x divide-gray-400 text-center p-1`}>
      {slice.fields.map((link, index) => {
        return (
          <li key={`card-${index}`} className='flex-auto'>
            <a className='block py-1 text-gray-900 hover:bg-gray-200' href={get(link, 'link_location')}>{get(link, 'link_name')}</a>
          </li>
        )
      })}
    </ul>
  )
}

InternalNavigation.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default InternalNavigation
