import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'

const FAQs = ({ slice }) => {
  return (
    <React.Fragment>
      <RenderBody slice={slice} />
    </React.Fragment>
  )
}

class RenderBody extends PureComponent {
  constructor() {
    super()
    this.state = {
      FAQExpanded: false
    }
    this.toggleFAQ = this.toggleFAQ.bind(this);
  }
  toggleFAQ(id) {
    if (id === this.state.FAQExpandedId) {
      this.setState({
        FAQExpandedId:false
      })
    } else {
      this.setState({
        FAQExpandedId:id
      })
    }
  }

  render() {
    const slice = this.props.slice
    return (
      <div className='bg-white p-4'>
        {get(slice.primary, 'faq_title') && (
          <h2 className='heading'>{slice.primary.faq_title}</h2>
        )}
        {get(slice.primary, 'faq_text') && (
          <p>{slice.primary.faq_text}</p>
        )}
        <ul className={`component-${slice.type}`}>
          {slice.fields.map((faq, index) => {
            return (
              <li className='overflow-hidden border-b transform transition-all my-1' key={`faq-${index}`}>
                <button className={`m-0 p-4 text-brand-blue font-semibold text-lg block hover:text-brand-orange w-full text-left outline-none ${this.state.FAQExpandedId === faq.question ? 'open': 'closed'}`} aria-haspopup='true' aria-expanded={this.state.FAQExpanded} onClick={() => this.toggleFAQ(`${faq.question}`)} onKeyDown={() => this.toggleFAQ(`${faq.question}`)}>
                  {faq.question}
                </button>
                <div className={`text text-lg p-4 ${this.state.FAQExpandedId === faq.question ? 'block': 'hidden'}`}>
                  {RichText.render(get(faq, 'answer'), linkResolver)}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}


RenderBody.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default FAQs
