import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';

const ResponsiveImage = ({ containerClasses, desktopClasses, image, imageClasses, loading, sizes }) => {
  if (image.mobile) {
    var mobileImage = image.mobile.url.replace(/&h=[0,9]/, '')
    var mobilePadding = {
      paddingBottom: 100 / (get(image.mobile.dimensions, 'width') / get(image.mobile.dimensions, 'height')) + '%',
    }
  }

  var desktopImage = image.url.replace(/&h=[0-9]*/, '').replace(/&w=[0-9]*/, '')
  var desktopPadding = {
    paddingBottom: 100 / (get(image.dimensions, 'width') / get(image.dimensions, 'height')) + '%',
  }

  var imageLoading = loading || 'lazy'
  var imageSizes = sizes || '(max-width: 768px) 768px, 100vw'

  return (
    <div className={containerClasses}>
      {mobilePadding ? (
        <>
          <div style={desktopPadding} className='hidden md:block lg:block xl:block w-full h-full'/>
          <div style={mobilePadding} className='block md:hidden lg:hidden xl:hidden w-full bg-cover'/>
        </>
      ):(
        <div style={desktopPadding} className={desktopClasses}/>
      )}
      <picture>
        <source
          media='(min-width: 768px)'
          srcSet={`${desktopImage}&w=300 300w,
                   ${desktopImage}&w=600 600w,
                   ${desktopImage}&w=1200 1200w,
                   ${desktopImage}&w=1800 1800w,
                   ${desktopImage}&w=2340 2340w`}
          sizes={imageSizes}
        />
        <img
          alt={get(image, 'alt') || ''}
          className={imageClasses}
          loading={imageLoading}
          sizes={imageSizes}
          src={`${get(image, 'url')}&amp;w=1200`}
          srcSet={`${mobileImage || desktopImage}&w=300 300w,
                   ${mobileImage || desktopImage}&w=600 600w,
                   ${mobileImage || desktopImage}&w=1200 1200w,
                   ${mobileImage || desktopImage}&w=1800 1800w,
                   ${mobileImage || desktopImage}&w=2340 2340w`}
        />
      </picture>
    </div>
  )
}

ResponsiveImage.defaultProps = {
  containerClasses: 'relative overflow-hidden w-full',
  imageClasses: 'absolute w-full h-auto top-0 left-0',
  desktopClasses: 'w-full bg-cover',
}

ResponsiveImage.propTypes = {
  containerClasses: PropTypes.string,
  imageClasses: PropTypes.string,
  desktopClasses: PropTypes.string,
  image: PropTypes.object.isRequired,
  loading: PropTypes.string,
  sizes: PropTypes.string,
}

export default ResponsiveImage
