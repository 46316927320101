import React from 'react';
import ResponsiveImage from '../responsiveImage'

export const DotButton = ({ selected, onClick, label }) => (
  <button
    className={`rounded-full w-4 h-4 mx-3 outline-none embla__dot${selected ? " is-selected bg-orange-600" : " bg-brand-blue-lighter"}`}
    onClick={onClick}>
    <span className='sr-only'>dot button</span>
  </button>
);

export const SliceDotButton = ({ selected, onClick, label, image }) => (
  <>
    {label &&  (
      <button
        className='embla__dot text-brand-blue font-light focus:outline-none'
        onClick={onClick}>
        <span className={`inline-block rounded-full w-4 h-4 -my-px ml-2 mr-1${selected ? " is-selected bg-orange-600" : " bg-white"}`}></span>
        {label ? (
          <span>{label}</span>
        ):(
          <span className='sr-only'>dot button</span>
        )}
      </button>
    )}
    {image && (
      <button
        className='embla__image-dot shadow focus:outline-none'
        onClick={onClick}>
        {image ? (
          <ResponsiveImage image={image} sizes='(min-width: 640px) calc(100vw - 3rem), (min-width: 768px) calc(66vw - 2rem), (min-width: 1024px) calc(66vw - 2.5rem), (min-width: 1280px) 805px, calc(100vw - 1rem)' />
        ):(
          <span className='sr-only'>dot button</span>
        )}
      </button>
    )}
  </>
);

export const PrevButton = ({ enabled, onClick }) => (
  <button
    className='absolute left-0 w-10 h-10 xl:ml-3 -mt-10 rounded-full hover:opacity-75 flex justify-center outline-none'
    onClick={onClick}
    disabled={!enabled}
    style={{top:'50%'}}
  >
    <div className='sr-only'>Previous</div>
    <svg className='h-6 mt-2 text-current' fill='currentColor' viewBox='137.718 -1.001 366.563 644'>
      <path d='M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z' />
    </svg>
  </button>
);

export const NextButton = ({ enabled, onClick }) => (
  <button
    className='absolute right-0 w-10 h-10 xl:mr-3 -mt-10 rounded-full hover:opacity-75 flex justify-center outline-none'
    onClick={onClick}
    disabled={!enabled}
    style={{top:'50%'}}
  >
    <div className='sr-only'>Next</div>
    <svg className='h-6 mt-2 text-current' fill='currentColor' viewBox='0 0 238.003 238.003'>
      <path d='M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z' />
    </svg>
  </button>
);
